<template>
  <div class="amenitites mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">Ausstattungen</h1>
        </v-col>
        <v-col cols="auto">
          <tooltip-button
            icon="add"
            text="Austattung erfassen"
            color="primary"
            @click="openCreateDialog()"
            classNames="ml-3"
            position="top"
          />
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <remote-data-table
            :url="'backoffice/amenities'"
            :search-enabled="true"
            :headers="headers"
            ref="amenitiesDatatable"
          >
            <template v-slot:item.action="{ item }">
              <v-btn
                x-small
                class="elevation-0 ml-3 mt-1 mb-1"
                @click="openEditDialog(item.id)"
                color="default"
                fab
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
              <v-btn
                x-small
                class="elevation-0 ml-1 mt-1 mb-1"
                @click="openDeleteDialog(item.id, item.name)"
                color="default"
                fab
              >
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
          </remote-data-table>
        </v-card-text>
      </v-card>
      <amenity-dialog
        ref="amenityDialog"
        @saved="$refs.amenitiesDatatable.getDataFromApi()"
      />
      <delete-dialog
        ref="deleteDialog"
        @deleted="$refs.amenitiesDatatable.getDataFromApi()"
      />
    </v-container>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import TooltipButton from "@/components/forms/TooltipButton";
import RemoteDataTable from "@/components/RemoteDataTable";
import AmenityDialog from "@/components/dialogs/AmenityDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";

export default {
  props: {
    config: {
      type: Object
    }
  },

  components: {
    TooltipButton,
    RemoteDataTable,
    AmenityDialog,
    DeleteDialog
  },

  data: () => ({
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Name", align: "left", sortable: true, value: "name" },
      {
        text: "Aktionen",
        align: "right",
        sortable: false,
        value: "action",
        width: 150
      }
    ],
    search: null
  }),

  watch: {},

  computed: {},

  mounted() {
    this.$nextTick(() => {});
  },

  methods: {
    ...mapMutations("app", [
      "setFullscreenDialogComponent",
      "setFullscreenDialogConfig",
      "setFullscreenDialogComponentState"
    ]),

    /**
     * Open create dialog
     */
    openCreateDialog() {
      this.$refs.amenityDialog.mode = "create";
      this.$refs.amenityDialog.open = true;
    },

    /**
     * Open edit dialog
     */
    openEditDialog(id) {
      this.$refs.amenityDialog.mode = "edit";
      this.$refs.amenityDialog.amenityId = id;
      this.$refs.amenityDialog.open = true;
    },

    /**
     * Open delete dialog
     */
    openDeleteDialog(id, name) {
      this.$refs.deleteDialog.entityId = id;
      this.$refs.deleteDialog.entityName = `#${id} ${name}`;
      this.$refs.deleteDialog.entityUrl = "amenities";
      this.$refs.deleteDialog.open = true;
    },

    /**
     * Close overlay component.
     */
    close() {
      this.setFullscreenDialogComponent(null);
      this.setFullscreenDialogConfig({});
      this.setFullscreenDialogComponentState();
    }
  }
};
</script>

<style lang="scss"></style>
